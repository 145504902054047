import req from './request'

const DEFAULT_ENDPOINTS = {
    list: '/anti-fraud',
    config: '/anti-fraud/list-config',
    driverException: '/anti-fraud/driver-exception'
}

export default {
    list(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.list, param)
    },
    config(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.config, param)
    },
    driverException(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driverException, param)
    }
}