<template>
   <transition name="modal" mode="out-in">
        <div class="modal__mask" @click="$emit('close')" v-if="show">
            <div class="modal__wrapper">
                <div class="modal__container" @click.stop>
                    <div class="modal__top">
                        <div class="modal__header">
                            <slot name="header">
                            </slot>
                        </div>
                        <button v-if="showCloseButton" class="modal___button button button_with_icon button_type_icon button_color_transp-black" @click="$emit('close')">
                            <svg aria-hidden="true" width="24" height="24">
                                <use href="#icon-close"></use>
                            </svg>
                        </button>
                    </div>

                    <div class="modal__body">
                        <slot name="body">
                        </slot>
                    </div>

                    <div class="modal__footer">
                        <slot name="footer">
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',
    props:{
        show:{
            type:Boolean,
            default:false
        },
        showCloseButton: {
            type:Boolean,
            default:true
        }
    },
    mounted() {
        document.addEventListener('keydown', (event) => {
            if (event.keyCode === 27) {
                this.$emit('close')
            }
        });
    }
}
</script>

<style lang="scss">
@import '@scss/mixins';
    .modal {
        &__mask {
            position: fixed;
            z-index: 9998;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            transition: opacity 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__wrapper {
            display: table-cell;
            vertical-align: middle;
        }

        &__container {
            position: relative;
            min-width: 300px;
            margin: 0 auto;
            padding: 48px 32px;
            background: #FFFFFF;
            border-radius: 24px;
            display: flex;
            flex-direction: column;
            max-width: 100%;
            max-height: 80vh;
        }

        &__top {
            display: flex;
            width: 100%;
        }

        &___button {
            &.button, & {
                margin-right: 0;
                margin-left: 5px;
            }
        }

        &__header {
            flex: 1 1 100%;
            padding-left: 45px;
            p {
                text-align: center;
                font-size: 32px;
                font-weight: 600;
                line-height: 40px;
                white-space: nowrap;
            }
        }
        &__body {
            margin: 20px 0;
            overflow: hidden;
            max-width: 80vw;
            max-height: 80vh;
            @include scrollbar();
            > div {
                padding-bottom: 3px;
            }
        }

        &__default-button {
            float: right;
        }

        &__footer {
            display: flex;
            .button {
                margin-left: 20px;
            }
            .flex-right{
                margin-left: auto;
                display: flex;
            }
        }
    }



</style>