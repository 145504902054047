import req from './request'

const DEFAULT_ENDPOINTS = {
    get: '/overdraft',
    calculate: '/overdraft/calculate'
}

export default {
    
    getOverdraft(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.get, param)
    },

    calculate(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.calculate, param)
    }
}